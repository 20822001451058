export enum ShipName {
  BrilliantLady = 'Brilliant Lady',
  ResilientLady = 'Resilient Lady',
  ScarletLady = 'Scarlet Lady',
  ValiantLady = 'Valiant Lady',
}

export enum ShipCode {
  BrilliantLady = 'BR',
  ResilientLady = 'RS',
  ScarletLady = 'SC',
  ValiantLady = 'VL',
}
