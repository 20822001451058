import type { PayloadAction } from '@reduxjs/toolkit';

import { createSlice } from '@reduxjs/toolkit';

import type { TCabinCategoriesAvailabilityRequest } from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';
import type { TCabinCategory } from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';

import { CABIN_CATEGORIES_ERROR_TYPES } from './constants';
import prepareCabinCategories from './prepareCabinCategories';

type CabinCategoriesState = {
  apiPayload?: TCabinCategoriesAvailabilityRequest;
  data?: TCabinCategory[];
  error?: {
    message?: unknown;
    type: string;
  };
  isLoaded?: boolean;
  isLoading?: boolean;
  isSeatMapVrLoaded?: boolean;
};

const initialState: CabinCategoriesState = {
  isLoaded: false,
  isLoading: false,
  isSeatMapVrLoaded: false,
};

const cabinCategoriesSlice = createSlice({
  initialState,
  name: 'cabinCategories',
  reducers: {
    clearData: ({ isSeatMapVrLoaded }) => ({ ...initialState, isSeatMapVrLoaded }),
    setData: (state, action: PayloadAction<{ cabinCategories: TCabinCategory[]; shipCode?: string }>) => {
      const { cabinCategories, shipCode } = action.payload;
      state.data = prepareCabinCategories(structuredClone(cabinCategories), shipCode);
      state.error = cabinCategories?.length ? initialState.error : { type: CABIN_CATEGORIES_ERROR_TYPES.FETCH_ERROR };
      state.isLoaded = true;
      state.isLoading = false;
    },
    setError: (state, action: PayloadAction<{ message?: unknown; type: string }>) => {
      state.apiPayload = undefined;
      state.data = undefined;
      state.error = action.payload;
      state.isLoaded = true;
      state.isLoading = false;
    },
    setLoading: (state, action: PayloadAction<TCabinCategoriesAvailabilityRequest>) => {
      state.apiPayload = action.payload;
      state.isLoading = true;
    },
    setSeatMapVrLoaded: (state) => {
      state.isSeatMapVrLoaded = true;
    },
    setUnavailableCabin: (state, action: PayloadAction<string>) => {
      const subCategoryCode = action.payload;
      state.data?.forEach((category) => {
        category.submetas.forEach((subMeta) => {
          subMeta.cabinTypes.forEach((cabinType) => {
            if (cabinType.code === subCategoryCode) {
              cabinType.isAvailable = false;
            }
          });
          subMeta.isAvailable = subMeta.cabinTypes.some(({ isAvailable }) => isAvailable);
        });
        category.isAvailable = category.submetas.some(({ isAvailable }) => isAvailable);
      });
    },
    setUnavailableCategory: (state, action: PayloadAction<string>) => {
      const exhaustedCategoryCode = action.payload;
      const exhaustedCategory = state.data?.find((category) => category.code === exhaustedCategoryCode);
      if (exhaustedCategory) {
        exhaustedCategory.isAvailable = false;
      }
    },
  },
});

export const cabinCategoryActions = cabinCategoriesSlice.actions;
export default cabinCategoriesSlice.reducer;
