import type { vwoInstance } from 'vwo-node-sdk';

import type { VWOFlag } from '@/types/vwo';

import { VWO_DEFAULTS } from '@/constants/settings';
import { VWO_FEATURE_FLAG_VALUES_RECEIVED } from '@/constants/vwoConstants';
import { getSessionStorageValue, setSessionStorageValue } from '@/helpers/util/storage';
import { getVWOUserId } from '@/helpers/vwo/getVWOUserId';

import { getVWOForceOverride } from './getVWOForceOverride';

export const getVWOValue = ({ key, vwoClient }: { key: string | VWOFlag; vwoClient?: vwoInstance }) => {
  const userId = getVWOUserId();
  const vwoForceOverride = getVWOForceOverride(key);
  const defaultValue = VWO_DEFAULTS[key as keyof typeof VWO_DEFAULTS];
  let vwoValue;
  try {
    if (vwoClient) {
      vwoValue = vwoClient.isFeatureEnabled(key, userId);
      const prevVWOConfig = getSessionStorageValue(VWO_FEATURE_FLAG_VALUES_RECEIVED);
      setSessionStorageValue(VWO_FEATURE_FLAG_VALUES_RECEIVED, { ...prevVWOConfig, [key]: vwoValue });
    }
  } catch (err) {
    console.error('VWO feature cannot be redden');
  }
  return vwoForceOverride ?? vwoValue ?? defaultValue;
};
