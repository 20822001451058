import {
  ADD_OTHER_GUEST_DETAILS_ERROR,
  ADD_OTHER_GUEST_DETAILS_SUCCESS,
  SAILOR_DETAILS_BOOK_NOW_CLEAR,
  SAILOR_DETAILS_BOOK_NOW_ERROR,
  SAILOR_DETAILS_BOOK_NOW_SUCCESS,
} from '@/constants/actionTypes';

const initState = { data: [], error: {} };

export default function bookNowReducer(store = initState, action) {
  switch (action.type) {
    case ADD_OTHER_GUEST_DETAILS_ERROR:
    case SAILOR_DETAILS_BOOK_NOW_ERROR:
      return { ...store, data: {}, error: action.payload };
    case ADD_OTHER_GUEST_DETAILS_SUCCESS:
    case SAILOR_DETAILS_BOOK_NOW_SUCCESS:
      return { ...store, data: action.payload, error: {} };
    case SAILOR_DETAILS_BOOK_NOW_CLEAR:
      return initState;
    default:
      return store;
  }
}
