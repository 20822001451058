import { type CaseReducer, createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { MnvvData } from './types';

const initialState = {} as MnvvData;

/**
 * Case Reducers
 */

const clearCase: CaseReducer<MnvvData, PayloadAction<undefined>> = () => ({ ...initialState });

const { actions, reducer } = createSlice({
  initialState,
  name: 'mnvvData',
  reducers: { clear: clearCase },
});

export const { clear: mnvvDataClear } = actions;

export default reducer;
