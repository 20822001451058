import { createSelector } from '@reduxjs/toolkit';

import type { TCurrencyCode } from '@/infra/types/common';
import type { RootState } from '@/store';

export const selectCommon = (state: RootState) => state?.common;

export const selectResources = createSelector(selectCommon, (common) => common?.resources);

export const selectLookup = createSelector(selectCommon, (common) => common?.lookup);

export const selectSettings = createSelector(selectCommon, (common) => common?.settings);

// please do not add default values here. in case if you need it create separated selector
export const selectCountryCode = createSelector(selectCommon, (common) => common?.geoLocation?.countryCode);
export const selectStateCode = createSelector(selectCommon, (common) => common?.geoLocation?.stateCode);

export const selectDefaultCurrencyCode = createSelector(
  selectLookup,
  selectSettings,
  (lookup, settings) => (lookup?.currenciesData?.defaultCurrencyCode || settings?.defaultCurrencyCode) as TCurrencyCode,
);

export const selectIsPersistentReady = createSelector(selectCommon, (common) => common?.isPersistentReady);
