export const ACCOUNTS_DASHBOARD_DATA_SUCCESS = 'ACCOUNTS_DASHBOARD_DATA_SUCCESS';
export const ACCOUNTS_DASHBOARD_DATA_ERROR = 'ACCOUNTS_DASHBOARD_DATA_ERROR';

export const SET_TOKEN_SUCCESS = 'SET_TOKEN_SUCCESS';
export const SET_TOKEN_ERROR = 'SET_TOKEN_ERROR';

export const SET_AUTHENTICATION_INITIALIZED_SUCCESS = 'SET_AUTHENTICATION_INITIALIZED_SUCCESS';

export const GET_VOYAGE_SEARCH_DATA_START = '@@itineraryResults/GET_VOYAGE_SEARCH_DATA_START';
export const GET_VOYAGE_SEARCH_DATA_SUCCESS = '@@itineraryResults/GET_VOYAGE_SEARCH_DATA_SUCCESS';
export const GET_VOYAGE_SEARCH_DATA_FAILURE = '@@itineraryResults/GET_VOYAGE_SEARCH_DATA_FAILURE';
export const SET_DEFAULT_PRICE_RANGE = '@@itineraryResults/SET_DEFAULT_PRICE_RANGE';

export const VOYAGE_SEARCH_VOYAGE_EXHAUST_ERROR_SET = 'VOYAGE_SEARCH_VOYAGE_EXHAUST_ERROR_SET';

export const WHATS_INCLUDED_SUCCESS = 'WHATS_INCLUDED_SUCCESS';
export const WHATS_INCLUDED_ERROR = 'WHATS_INCLUDED_ERROR';

export const SUMMARY_SAILING_DATA_SUCCESS = 'SUMMARY_SAILING_DATA_SUCCESS';
export const SUMMARY_SAILING_DATA_ERROR = 'SUMMARY_SAILING_DATA_ERROR';
export const SUMMARY_SAILING_DATA_CLEAR_ERROR = 'SUMMARY_SAILING_DATA_CLEAR_ERROR';
export const SUMMARY_SAILING_DATA_FETCHING_START = 'SUMMARY_SAILING_DATA_FETCHING_START';
export const CLEAR_SUMMARY_SAILING_DATA = 'CLEAR_SUMMARY_SAILING_DATA';

export const SUMMARY_CALCULATE_INVOICE_CLEAR = 'SUMMARY_CALCULATE_INVOICE_CLEAR';
export const SUMMARY_CALCULATE_INVOICE_START = 'SUMMARY_CALCULATE_INVOICE_START';
export const SUMMARY_CALCULATE_INVOICE_SUCCESS = 'SUMMARY_CALCULATE_INVOICE_SUCCESS';
export const SUMMARY_CALCULATE_INVOICE_ERROR = 'SUMMARY_CALCULATE_INVOICE_ERROR';

export const SUMMARY_UNHOLD_CABIN_SUCCESS = 'SUMMARY_UNHOLD_CABIN_SUCCESS';
export const SUMMARY_UNHOLD_CABIN_ERROR = 'SUMMARY_UNHOLD_CABIN_ERROR';

export const SAILOR_DETAILS_BOOK_NOW_SUCCESS = 'SAILOR_DETAILS_BOOK_NOW_SUCCESS';
export const SAILOR_DETAILS_BOOK_NOW_ERROR = 'SAILOR_DETAILS_BOOK_NOW_ERROR';
export const SAILOR_DETAILS_BOOK_NOW_CLEAR = 'SAILOR_DETAILS_BOOK_NOW_CLEAR';

export const SAILOR_DETAILS_UPDATE_BOOKING_PRICE_SUCCESS = 'SAILOR_DETAILS_UPDATE_BOOKING_PRICE_SUCCESS';
export const SAILOR_DETAILS_UPDATE_BOOKING_PRICE_ERROR = 'SAILOR_DETAILS_UPDATE_BOOKING_PRICE_ERROR';

export const CONFIRMATION_SET_TRACKED_RESERVATION_NUMBER_SUCCESS =
  'CONFIRMATION_SET_TRACKED_RESERVATION_NUMBER_SUCCESS';
export const CONFIRMATION_SET_TRACKED_RESERVATION_NUMBER_ERROR = 'CONFIRMATION_SET_TRACKED_RESERVATION_NUMBER_ERROR';

export const PAYMENT_SET_TRANSACTION_DATA = 'PAYMENT_SET_TRANSACTION_ID';

export const PAYMENT_SET_PAYMENT_OPTION = 'PAYMENT_SET_PAYMENT_OPTION';

export const SET_TRAVEL_INSURANCE_DATA_SUCCESS = '@@sailorDetails/SET_TRAVEL_INSURANCE_DATA_SUCCESS';
export const SET_TRAVEL_INSURANCE_DATA_FAILURE = '@@sailorDetails/SET_TRAVEL_INSURANCE_DATA_FAILURE';

export const CONFIGURATION_SERVICE_SUCCESS = 'CONFIGURATION_SERVICE_SUCCESS';
export const CONFIGURATION_SERVICE_ERROR = 'CONFIGURATION_SERVICE_ERROR';

export const SUMMARY_GET_AVAILABLE_ZONES_SUCCESS = 'SUMMARY_GET_AVAILABLE_ZONES_SUCCESS';
export const SUMMARY_GET_AVAILABLE_ZONES_ERROR = 'SUMMARY_GET_AVAILABLE_ZONES_ERROR';

export const SUMMARY_GET_NEW_CABIN_SUCCESS = 'SUMMARY_GET_NEW_CABIN_SUCCESS';
export const SUMMARY_GET_NEW_CABIN_ERROR = 'SUMMARY_GET_NEW_CABIN_ERROR';

export const ADD_OTHER_GUEST_DETAILS_SUCCESS = 'ADD_OTHER_GUEST_DETAILS_SUCCESS';
export const ADD_OTHER_GUEST_DETAILS_ERROR = 'ADD_OTHER_GUEST_DETAILS_ERROR';

export const UPDATE_RESERVATION_SUCCESS_STATUS = 'UPDATE_RESERVATION_SUCCESS_STATUS';
export const UPDATE_RESERVATION_ERROR_STATUS = 'UPDATE_RESERVATION_ERROR_STATUS';

export const BOOK_NOW_ENCRYPTED_RESERVATION_SUCCESS = 'BOOK_NOW_ENCRYPTED_RESERVATION_SUCCESS';

// clear login data
export const CLEAR_LOGGED_IN_SESSION_DATA = 'CLEAR_LOGGED_IN_SESSION_DATA';

export const SET_PAY_IN_FULL_PROMO_APPLIED_FLAG = 'SET_PAY_IN_FULL_PROMO_APPLIED_FLAG';

export const CONFIRMATION_TODO_LIST_SUCCESS = 'CONFIRMATION_TODO_LIST_SUCCESS';
export const CONFIRMATION_TODO_LIST_FAILURE = 'CONFIRMATION_TODO_LIST_FAILURE';

export const HTTP_API_ERRORS_CLEAR = 'HTTP_ERROR_CLEAR';

export const PROMOTION_VOYAGES_DATA_SUCCESS = 'PROMOTION_VOYAGES_DATA_SUCCESS';
export const PROMOTION_VOYAGES_DATA_ERROR = 'PROMOTION_VOYAGES_DATA_ERROR';

export const FILTERS_APPLY = 'FILTERS_APPLY';

export const SAILOR_FORM_UPDATED = 'SAILOR_FORM_UPDATED';
export const SAILOR_FORM_RESET = 'SAILOR_FORM_RESET';
