import { createSelector } from '@reduxjs/toolkit';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import uniq from 'lodash/uniq';

import type { FilteredPackage, Package } from '@/infra/types/voyageInfo/package';
import type { RootState } from '@/store';

import { selectLookup } from '@/ducks/common/selectors';
import {
  selectAccessible,
  selectCabins,
  selectCabinType,
  selectDurations,
  selectFromDate,
  selectHomePorts,
  selectMaxPrice,
  selectMetaType,
  selectMinPrice,
  selectPriceType,
  selectSailors,
  selectSelectedItineraries,
  selectSelectedPortsOfCall,
  selectShipList,
  selectSortType,
  selectVoyageIds,
  selectWeekend,
} from '@/ducks/filters/selectors';
import { getFiltersOptions } from '@/ducks/filtersOptions/selectors';
import { selectProfileData } from '@/features/profile/selectors';
import createMemoSelector from '@/helpers/createMemoSelector';
import { getAmountPerItem, getAmountPerVoyage } from '@/helpers/data/mappers/amount';
import { checkForValueType } from '@/helpers/util';
import { FiltersPriceType, type TFiltersDuration } from '@/infra/types/common/filters';

import {
  filterByPromoVoyages,
  filterDeparturePorts,
  filterShips,
  getPackagesForFilteredSailings,
  getSailingsFromPackages,
} from './getters';
import { groupPackagesWithSameName } from './groupPackagesWithSameNames';
import { type TFetchVoyagesSuccessOptions, type TFilteredPackages } from './slice';
import { sortSailings } from './utility';

const getRoot = (state: RootState) => state.chooseVoyageNew;

export const selectMainPackages = (state: RootState) => getRoot(state).mainPackages.packages;
export const selectPackagesIsLoading = (state: RootState) => getRoot(state).isLoading;
export const selectMainPackagesIsLoaded = (state: RootState) => getRoot(state).mainPackages.isLoaded;
export const selectExtendedPackages = (state: RootState) => getRoot(state).mainPackages.extendedPackages;
export const selectMainSailings = (state: RootState) => getRoot(state).mainPackages.sailings;
export const selectDefaultGenericCategoryCodes = (state: RootState) => getRoot(state).defaultGenericCategoryCodes;
export const selectGenericCategoryCodes = (state: RootState) => getRoot(state).genericCategoryCodes;
export const selectDefaultPackages = (state: RootState) => getRoot(state).mainPackages.defaultPackages;
export const selectFilteredPackages = (state: RootState) => getRoot(state).filteredPackages.packages;
export const selectFilteredSailings = (state: RootState) => getRoot(state).filteredPackages.sailings;
export const selectIsMultipleVoyageFilterActive = (state: RootState) => selectVoyageIds(state)?.length > 0;
export const selectVoyagesApiPayload = (state: RootState) => getRoot(state).voyagesApiPayload;

export const selectIsAccessibleCabinAvailable = (state: RootState) => {
  return selectFilteredSailings(state)?.some((sailing) => sailing.isAccessible) === true;
};

export const selectTrackedSailings = (state: RootState) => getRoot(state)?.trackedSailings;

export const getExternalId = (state: RootState) => selectProfileData(state)?.externalRefId || '';

export const filterPriceRange = (state: RootState, packages: Package[]) => {
  const cabins = selectCabins(state);
  const maxPrice = selectMaxPrice(state);
  const minPrice = selectMinPrice(state);
  const priceType = selectPriceType(state);
  const sailors = selectSailors(state);

  if (minPrice === null && maxPrice === null) {
    return packages;
  }

  const sailings = getSailingsFromPackages(packages);
  const filteredSailings = sailings.filter((voyage) => {
    let price = getAmountPerItem(voyage.startingPrice, { cabins, priceType, sailors });

    if (priceType === FiltersPriceType.sailorPerNight) {
      price = getAmountPerVoyage(voyage.startingPrice, voyage.duration, { sailors });
    }

    if (minPrice === null && maxPrice === null) {
      return true;
    }

    if (minPrice === null) {
      return price <= maxPrice;
    }

    if (maxPrice === null) {
      return price >= minPrice;
    }

    return price >= minPrice && price <= maxPrice;
  });

  let sailingList = [];
  if (priceType === FiltersPriceType.sailorPerNight) {
    sailingList = orderBy(
      filteredSailings,
      (sail) => getAmountPerVoyage(sail.startingPrice, sail.duration, { sailors }),
      'asc',
    );
  } else {
    sailingList = orderBy(
      filteredSailings,
      (itinerary) => getAmountPerItem(itinerary.startingPrice, { cabins, priceType, sailors }),
      'asc',
    );
  }

  return getPackagesForFilteredSailings(sailingList, packages);
};

const filterAccessible = (packages: Package[], accessible: boolean) => {
  const sailings = getSailingsFromPackages(packages);
  if (!checkForValueType(sailings)) {
    return packages;
  }
  let updatedSailings = [];
  if (accessible) {
    updatedSailings = filter(sailings, 'isAccessible');
  } else {
    updatedSailings = sailings;
  }
  return getPackagesForFilteredSailings(updatedSailings, packages);
};

const filterWeekend = (packages: Package[], weekend: boolean) => {
  const sailings = getSailingsFromPackages(packages);
  if (!checkForValueType(sailings)) {
    return packages;
  }
  let updatedSailings = [];
  if (weekend) {
    updatedSailings = filter(sailings, 'weekend');
  } else {
    updatedSailings = sailings;
  }
  return getPackagesForFilteredSailings(updatedSailings, packages);
};

const filterItineraries = (packages: Package[], selectedItineraries: string[]) =>
  packages.filter((pckg) => selectedItineraries.includes(pckg.packageCode));

const filterPorts = (packages: Package[], selectedPorts: string[]) =>
  packages.filter((pckg) =>
    pckg?.sailingList?.some((sailing) =>
      sailing?.ports?.some((currentPort) => selectedPorts.includes(currentPort.code)),
    ),
  );

const filterDurations = (packages: Package[], durations: TFiltersDuration[]) => {
  if (!packages.length || !durations.length) {
    return packages;
  }
  return packages.filter((s) =>
    durations.some((duration) => {
      if (duration.max && duration.min) {
        return s.duration >= duration.min && s.duration <= duration.max;
      }
      return s.duration >= duration.min;
    }),
  );
};

/**
 * @see https://virginvoyages.atlassian.net/browse/MSH-126394
 */
const expandSelectedItineraries = (state: RootState) => {
  const itineraries = selectSelectedItineraries(state);
  const mainPackages = selectMainPackages(state);

  const packageNames = mainPackages.reduce<string[]>(
    (packageNames, pkg) => (itineraries.includes(pkg.packageCode) ? [...packageNames, pkg.packageName] : packageNames),
    [],
  );

  return packageNames.length > 0
    ? mainPackages.reduce<string[]>(
        (packageCodes, pkg) =>
          packageNames.includes(pkg.packageName) ? [...packageCodes, pkg.packageCode] : packageCodes,
        [],
      )
    : itineraries;
};

export const selectPackagesFilteredByDestinations = (state: RootState) => {
  const mainPackages = selectMainPackages(state);
  const itineraries = expandSelectedItineraries(state);
  const portsOfCall = selectSelectedPortsOfCall(state);

  if (itineraries?.length > 0 || portsOfCall?.length > 0) {
    return uniq([...filterItineraries(mainPackages, itineraries), ...filterPorts(mainPackages, portsOfCall)]);
  }

  return mainPackages;
};

export const selectDisabledHomePortsCodes = createSelector(
  selectPackagesFilteredByDestinations,
  selectLookup,
  getFiltersOptions,
  (packages, lookup, filtersOptions) => {
    const allPorts = packages.reduce<string[]>(
      (allPorts, pkg) => [
        ...allPorts,
        ...(pkg.sailingList?.reduce<string[]>(
          (allPorts, sailing) => [...allPorts, ...(sailing.ports?.map((port) => port.code) || [])],
          [],
        ) || []),
      ],
      [],
    );
    const homePorts = lookup.homePorts || filtersOptions.homePorts;
    return homePorts.reduce<string[]>(
      (portsCodes, port) => (!allPorts.includes(port.code) ? [...portsCodes, port.code] : portsCodes),
      [],
    );
  },
);

export const getFilteredPackages = (state: RootState) => {
  const fromDate = selectFromDate(state);
  const priceType = selectPriceType(state);
  const sailors = selectSailors(state);
  const sortType = selectSortType(state);
  const durations = selectDurations(state);
  const weekend = selectWeekend(state);
  const accessible = selectAccessible(state);
  const ships = selectShipList(state);
  const homePorts = selectHomePorts(state);

  let result = selectPackagesFilteredByDestinations(state) || {};

  result = filterDurations(result, durations);
  result = filterWeekend(result, weekend);
  result = filterAccessible(result, accessible);
  result = filterPriceRange(state, result);
  result = filterShips(result, ships);
  result = filterDeparturePorts(result, homePorts);
  result = groupPackagesWithSameName(result);
  if (selectIsMultipleVoyageFilterActive(state)) {
    result = filterByPromoVoyages(selectVoyageIds(state), result);
  }

  return sortSailings(result, sortType, fromDate, priceType, sailors) as FilteredPackage[];
};

export const selectIsWeekendAvailableForPackages = (state: RootState) => {
  const packages = getFilteredPackages(state);
  const sailings = getSailingsFromPackages(packages);

  return sailings.some((obj) => obj.weekend);
};

export const getPresentDurationsForPackages = (state: RootState) => {
  const packages = selectPackagesFilteredByDestinations(state);
  const { durationOptions } = getFiltersOptions(state);
  if (durationOptions === undefined) {
    return [];
  }
  try {
    return durationOptions?.map((duration) => ({
      ...duration,
      isDisabled: !packages.some((s) => {
        if (duration.max && duration.min) {
          return s.duration >= duration.min && s.duration <= duration.max;
        }
        return s.duration >= duration.min;
      }),
    }));
  } catch (error) {
    console.log('error while reading duration options key::: ', error);
    return [];
  }
};

export const selectFetchVoyagesSuccessOptions = createMemoSelector(
  [selectCabinType, selectMetaType, selectVoyageIds],
  (cabinType, metaType, voyageIds): TFetchVoyagesSuccessOptions => ({
    isOmitGenericCategories: cabinType !== null && isEmpty(metaType),
    voyageIds,
  }),
);

export const selectMakeFilteredPackages = createMemoSelector([getFilteredPackages], (packages): TFilteredPackages => {
  const sailings = getSailingsFromPackages(packages);
  return { packages, sailings };
});
