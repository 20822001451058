import partition from 'lodash/partition';

import type { TCabinCategory } from '@/helpers/api/graphql/queries/cabinCategoriesAvailability';

import { CABIN_CATEGORY_CODES } from '@/constants/cabinCategoryCodes';
import { ShipCode } from '@/infra/types/voyageInfo/ship';

const SEA_TERRACE_SLIGHTLY_SMALLER_BALCONY_CODE = 'TS';
const filterNonExistentCabins = (categories: TCabinCategory[], shipCode?: string) => {
  if (shipCode === ShipCode.BrilliantLady) return;
  for (const category of categories) {
    for (const submeta of category.submetas) {
      submeta.cabinTypes = submeta.cabinTypes.filter(({ code }) => code !== SEA_TERRACE_SLIGHTLY_SMALLER_BALCONY_CODE);
    }
    category.submetas = category.submetas.filter((submeta) => !!submeta.cabinTypes?.length);
  }
};

/**
 * @see https://virginvoyages.atlassian.net/browse/MSH-135045
 */
const filterIncorrectCabinTypes = (categories: TCabinCategory[]) => {
  for (const category of categories) {
    for (const submeta of category.submetas) {
      submeta.cabinTypes = submeta.cabinTypes
        // Test cabinTypes has lowercase codes
        .filter(({ code }) => code === code.toUpperCase());
    }
    category.submetas = category.submetas.filter((submeta) => !!submeta.cabinTypes?.length);
  }
};

const setUnavailableForCabinsWithoutPrice = (categories: TCabinCategory[]) => {
  for (const category of categories) {
    for (const submeta of category.submetas) {
      for (const cabin of submeta.cabinTypes) {
        const { amount, currencyCode, originalAmount } = cabin.lowestAvailablePrice.totalPrice;
        if (amount == null || originalAmount == null || currencyCode == null) {
          cabin.isAvailable = false;
        }
      }
      submeta.isAvailable = submeta.cabinTypes.some(({ isAvailable }) => isAvailable);
    }
    category.isAvailable = category.submetas.some(({ isAvailable }) => isAvailable);
  }
};

const grouping = {
  groupUnderCode: 'TR',
  metaCabin: CABIN_CATEGORY_CODES.SEA_TERRACE,
  subMetaCodes: ['TL', 'TC'],
};
const customSubMetasGrouping = (categories: TCabinCategory[]) => {
  const category = categories.find(({ code }) => code === grouping.metaCabin);
  if (!category) return;

  const rootSubMeta = category.submetas?.find((subMeta) => subMeta.code === grouping.groupUnderCode);
  if (!rootSubMeta) return;

  const [cabinToGroup, otherCabins] = partition(category.submetas, (subMeta) =>
    grouping.subMetaCodes.includes(subMeta.code),
  );
  rootSubMeta.cabinTypes.push(...cabinToGroup);
  category.submetas = otherCabins;
};

/**
 * @see https://virginvoyages.atlassian.net/browse/MSH-111937?focusedCommentId=920984
 */
const filterEmptySubmetas = (categories: TCabinCategory[]) => {
  for (const category of categories) {
    category.submetas = category.submetas.filter((item) => !!item.name);
    category.isAvailable = category.submetas.some((subMeta) => subMeta.isAvailable);
  }
};

const prepareCabinCategories = (categories?: TCabinCategory[], shipCode?: string) => {
  if (!categories) return [];
  filterEmptySubmetas(categories);
  filterIncorrectCabinTypes(categories);
  filterNonExistentCabins(categories, shipCode);
  customSubMetasGrouping(categories);
  setUnavailableForCabinsWithoutPrice(categories);

  return categories;
};

export default prepareCabinCategories;
