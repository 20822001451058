import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@/store';

import { selectLookup } from '@/ducks/common/selectors';
import { selectCruisesCommonVoyagesDataRegions } from '@/ducks/cruisesCommon/selectors';
import { selectAgencyCurrencies, selectIsAgencyDataAvailable } from '@/ducks/fm/selectors';

export const selectLookupData = (state: RootState) => selectLookup(state);

export const selectServerISOtime = (state: RootState) => selectLookupData(state)?.serverISOtime;

export const selectLookupShips = (state: RootState) => selectLookupData(state)?.ships;

export const selectLookupStates = (state: RootState) => selectLookupData(state)?.states;

export const selectLookupCurrencies = (state: RootState) => selectLookupData(state)?.currencies;

export const selectSupportedCurrencies = (state: RootState) => selectLookupData(state)?.currenciesData?.currencies;

export const selectSupportedCurrenciesList = (state: RootState) =>
  selectSupportedCurrencies(state)?.map(({ code }) => code);

export const selectDefaultPackageCodes = (state: RootState) => selectLookupData(state)?.defaultPackageCodes;

export const selectLookupPromotionData = (state: RootState) => selectLookupData(state)?.promotionBanner;

export const selectApplicableCurrencies = createSelector(
  selectLookupCurrencies,
  selectSupportedCurrencies,
  selectAgencyCurrencies,
  selectIsAgencyDataAvailable,
  (allCurrencies, clientCurrencies, multiCurrencies, isFmLink) =>
    isFmLink ? allCurrencies?.filter(({ code }) => multiCurrencies.includes(code)) : clientCurrencies,
);

export const selectRegionCodePriority = createSelector(selectLookup, ({ regionCodePriority }) => regionCodePriority);

export const selectRegionsSortedByPriority = createSelector(
  selectCruisesCommonVoyagesDataRegions,
  selectRegionCodePriority,
  (regions, regionCodePriority) => {
    return regions
      .map((region) => ({
        ...region,
        priority: regionCodePriority?.find(({ code }) => code === region.id)?.priority || 1000,
      }))
      .sort((a, b) => a.priority - b.priority);
  },
);
