import { differenceInCalendarDays, isAfter, isBefore, isSameDay, isSameMonth, isValid } from 'date-fns';
import he from 'he';
import find from 'lodash/find';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import qs from 'query-string';

import { BookingChannelTypes } from '@/constants/settings';
import {
  getFilterAgencyCurrencyCode,
  getFilterAgencyId,
  getFilterAgencyIsAvailable,
  getFilterAgentBookingChannel,
  getFilterAgentId,
} from '@/ducks/fm/getters';
import { getMnvvReservation } from '@/ducks/mnvv/utils';
import { paths } from '@/ducks/routes';
import { env } from '@/environment';
import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';

import addressConfig from '../data/invariants/AddressConfig';
import { currentNYTime, formatDate, parse } from './dateUtil';
import { getSessionStorageValue, setSessionStorageValue } from './storage';

let isAccessKeyDetailsCalled = false;

export const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return 'IOS';
  }
  if (/android/i.test(userAgent)) {
    return 'android';
  }
  if (/Blackberry/i.test(userAgent)) {
    return 'blackberry';
  }
  return null;
};

export const isMobile = () => getMobileOperatingSystem() !== null;

export function getScrollbarWidth() {
  const documentWidth = parseInt(document.body.clientWidth, 10);
  const windowsWidth = parseInt(window.innerWidth, 10);

  return windowsWidth - documentWidth;
}

export function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

// Like lodash's kebabCase, but keep all special characters: "I want $50" -> "i-want-$50"
export const kebabCase = (str) => {
  if (!str) {
    return;
  }
  str.replace(/\s+/g, '-').toLowerCase();
};

// returns query parameters as an object
export const getQueryParameters = (url) => {
  const query = url.substr(url.lastIndexOf('?') + 1);
  const parameters = query.split('&').reduce((prev, curr) => {
    const p = curr.split('=');
    const previous = prev;
    previous[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
    return prev;
  }, {});
  return parameters;
};

// https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob#Polyfill
export const toBlobPolyfill = () => {
  if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
      value: function (callback, type, quality) {
        const dataURL = this.toDataURL(type, quality).split(',')[1];
        setTimeout(() => {
          const binStr = atob(dataURL);
          const len = binStr.length;
          const arr = new Uint8Array(len);

          for (let i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
          }

          callback(new Blob([arr], { type: type || 'image/png' }));
        });
      },
    });
  }
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const parseQueryString = (props) => {
  const parsed = props?.searchParams ? Object.fromEntries(props?.searchParams) : qs.parse(window.location?.search);

  const DEFAULT_SAILOR_COUNT = 1;

  const maxCabinCount = parseInt(props?.configServiceData?.maxCabinCount, 10);
  const maxCabinOccupancy = parseInt(props?.configServiceData?.maxCabinOccupancy, 10);
  const minCabinCount = parseInt(props?.configServiceData?.minCabinCount, 10);
  const minCabinOccupancy = parseInt(props?.configServiceData?.minCabinOccupancy, 10);
  const defaultSailorCount = parseInt(props?.configServiceData?.defaultSailorCount, 10);
  const defaultCabinCount = parseInt(props?.configServiceData?.defaultCabinCount, 10);
  const accessKeyEnabled = props?.configServiceData?.accessKeyEnabled || false;
  let defaultMinDate = parse(props?.voyageSearch?.minDate);
  const currentDateTime = props?.lookup?.serverISOtime;
  const currentDate = currentNYTime(currentDateTime);
  if (isBefore(defaultMinDate, currentDate)) {
    defaultMinDate = currentDate;
  }
  const defaultMaxDate = parse(props?.voyageSearch?.maxDate);
  const allowedCurrencies = props?.configServiceData?.allowedCurrencies || [];
  const durationList = props?.voyageSearch?.durations || [];
  const selectedVoyageId = getSessionStorageValue('selectedVoyageId');
  let sailorCount = parsed.sailors || defaultSailorCount || DEFAULT_SAILOR_COUNT;
  let cabinCount = parsed.cabins || defaultCabinCount;

  let currency = props?.lookup?.currenciesData?.defaultCurrencyCode || 'USD';
  let selectedRangeStart = defaultMinDate;
  let selectedRangeEnd = defaultMaxDate;
  let isCabinAccessible = props?.configServiceData?.isCabinAccessible || false;
  let showError = false;
  let durations = '';
  let cabinType = parsed.metaType || parsed.cabinType;
  let accessKey = '';

  if (!isEmpty(parsed)) {
    const sailors = parseInt(parsed.sailors, 10);
    if (isNumber(sailors)) {
      if (sailors >= minCabinOccupancy && sailors <= maxCabinCount * maxCabinOccupancy) {
        sailorCount = sailors;
      }
    }
    const cabins = parseInt(parsed.cabins, 10);
    if (isNumber(cabins)) {
      if (cabins >= minCabinCount && cabins <= maxCabinCount) {
        cabinCount = cabins;
      }
    }
    if (parsed.currencyCode) {
      const selectedCode = getFilterAgencyCurrencyCode() || parsed.currencyCode;
      const selectedCurrency = allowedCurrencies.filter(
        (currencyItem) => currencyItem.toLowerCase() === selectedCode.toLowerCase(),
      );
      if (selectedCurrency.length > 0) {
        [currency] = selectedCurrency;
      }
    }
    if (parsed.fromDate) {
      const fromDate = new Date(parsed.fromDate);
      if (isAfter(fromDate, defaultMinDate) && isBefore(fromDate, defaultMaxDate)) {
        selectedRangeStart = fromDate;
      }
    }
    if (parsed.toDate) {
      const toDate = new Date(parsed.toDate);
      if (isBefore(toDate, defaultMaxDate) && isAfter(toDate, defaultMinDate)) {
        selectedRangeEnd = parsed.toDate;
      }
    }

    if (isAfter(parse(selectedRangeStart), parse(selectedRangeEnd))) {
      selectedRangeStart = defaultMinDate;
      selectedRangeEnd = defaultMaxDate;
    }

    selectedRangeStart = isString(selectedRangeStart) ? parse(selectedRangeStart) : selectedRangeStart;
    selectedRangeEnd = isString(selectedRangeEnd) ? parse(selectedRangeEnd) : selectedRangeEnd;

    if (
      (isString(parsed?.isCabinAccessible) &&
        ['false', 'true'].includes((parsed?.isCabinAccessible || '').toLowerCase())) ||
      isBoolean(parsed?.isCabinAccessible)
    ) {
      isCabinAccessible = JSON.parse(`${parsed?.isCabinAccessible || 'false'}`.toLowerCase());
    }
    if (
      (isString(parsed?.showError) && ['false', 'true'].includes((parsed?.showError || '').toLowerCase())) ||
      isBoolean(parsed?.showError)
    ) {
      showError = JSON.parse(`${parsed?.showError || 'false'}`.toLowerCase());
    }
    if (parsed.durations) {
      if (isEqual(parsed.durations, 'weekend')) {
        durations = 'weekend';
      } else {
        const selectedDuration = durationList.filter((duration) => duration.value === parsed.durations);
        if (selectedDuration.length > 0) {
          durations = selectedDuration[0].value;
        }
      }
    }

    // The short form of the parameter may be found in old or external urls - "pkgCode" instead "packageCode"
    if (parsed.pkgCode && !parsed.packageCode) {
      parsed.packageCode = parsed.pkgCode;
    }
    if (parsed.accessKey) {
      accessKey = parsed.accessKey;
      accessKeyEnabled &&
        !isAccessKeyDetailsCalled &&
        isEmpty(props.accessKeyDetails) &&
        props.actions?.fetchAccessKeyDetailsData?.(accessKey);
      isAccessKeyDetailsCalled = true;
    }
  }
  const fromDate = isValid(selectedRangeStart) ? selectedRangeStart : parse(parsed.fromDate);
  const toDate = isValid(selectedRangeEnd) ? selectedRangeEnd : parse(parsed.toDate);

  return {
    ...parsed,
    ...(selectedVoyageId && { voyageId: selectedVoyageId }),
    accessKey,
    cabins: cabinCount,
    currencyCode: currency,
    durations,
    fromDate,
    isCabinAccessible,
    metaType: cabinType,
    packageCode: parsed.packageCode || parsed.pkgCode || parsed.selectedPackages?.[0],
    promoCode: parsed.promoCode || undefined,
    sailors: sailorCount,
    showError,
    toDate,
  };
};

export const getCountryAddressConfig = (countryCode) => find(addressConfig, ['countryCode', countryCode]);

export const decodeInnerHTML = (text) => he.decode(text || '');

export const copyToClipboard = async (link) => {
  if (navigator.clipboard) {
    await navigator.clipboard.writeText(link);
  } else {
    const input = await document.createElement('input');
    input.setAttribute('value', link);
    input.setAttribute('style', { display: 'none' });
    await document.body.appendChild(input);
    await input.select();
    await document.execCommand('copy');
    await document.body.removeChild(input);
  }

  return {
    isLinkCopiedToClipBoard: true,
  };
};

export const getRegionName = (packageCode, regions = []) => {
  const region = regions.find((r) => r.packages.some((packageData) => packageData.id === packageCode));
  return region ? region.name : '';
};

export const getPromotionTitle = (nodeType, promotionTitlesList) =>
  promotionTitlesList?.[nodeType] || promotionTitlesList.other;

export const clearBodyBackgroundClasses = (direction, ismobile) => {
  const { body } = document;

  body.style.borderRightWidth = 0;
  body.classList.remove('no-scroll', `flyout-open-${direction}`);
  if (ismobile) {
    body.style.position = '';
  }
};
export const addBodyBackgroundClasses = (direction, ismobile, borderWidth = 0) => {
  const { body } = document;
  const width = parseInt(borderWidth, 10);
  body.style.borderRightWidth = `${width}px`;
  body.classList.add('no-scroll', `flyout-open-${direction}`);
  if (ismobile) {
    body.style.position = 'fixed';
  }
};

export const getPaymentParams = (isPaymentLink) => (isPaymentLink ? { isPaymentLink } : undefined);

export const isDiscountAmount = (originalAmount, amount) => !!originalAmount && !isEqual(originalAmount, amount);
export const getPorts = (itinerary) => {
  const ports =
    itinerary &&
    itinerary
      .filter((item) => item.dayType !== 'sailing')
      .map((item) => item.portName)
      .join(' • ');
  return ports;
};

const getFlightDetailsByType = (allFlights, type, displayedPorts, sailorGuestref) => {
  const flights = allFlights.filter(
    (flight) => flight.flightType === type && flight.guestRefNumbers.includes(sailorGuestref),
  );

  let flightToDisplay = flights[0];
  // To check for connecting flight

  if (flights.length > 1) {
    flightToDisplay = flights.find(
      ({ debarkPort, embarkPort }) => displayedPorts.includes(embarkPort) || displayedPorts.includes(debarkPort),
    );
  }

  return {
    flight: flightToDisplay,
    hasConnectingFlight: flights.length > 1,
  };
};

export const getSpecialSailingDescription = (specialSailings, classificationCode) => {
  const specialSailingDescription = specialSailings.filter(
    (specialSailing) => specialSailing.id === classificationCode,
  );
  if (!isEmpty(specialSailingDescription)) {
    return specialSailingDescription[0].description;
  }

  return [];
};

export const getPrePostFlightDetails = (flightDetails, displayedPorts, sailorGuestref) => ({
  postFlight: getFlightDetailsByType(flightDetails, 'POST', displayedPorts, sailorGuestref),
  preFlight: getFlightDetailsByType(flightDetails, 'PRE', displayedPorts, sailorGuestref),
});

function calculateShiftInDays(flight) {
  const { debarkDateTime, embarkDateTime } = flight;
  const days = differenceInCalendarDays(parse(embarkDateTime), parse(debarkDateTime));
  if (!days) {
    return null;
  }
  const daysSymbol = days > 0 ? '+' : '';
  return daysSymbol + days;
}

export const fetchCurrentFlightDateDetails = (flight) => {
  const travelDetails = {
    dateRange: '',
    days: '',
  };
  const { debarkDateTime, embarkDateTime } = flight;

  if (isSameDay(parse(debarkDateTime), parse(embarkDateTime))) {
    travelDetails.dateRange = formatDate(debarkDateTime, 'd MMM');
  } else {
    const days = calculateShiftInDays(flight);
    travelDetails.days = days;
    const isMonth = isSameMonth(parse(debarkDateTime), parse(embarkDateTime));
    travelDetails.dateRange = `${formatDate(debarkDateTime, isMonth ? 'd' : 'd MMM')}-${formatDate(
      embarkDateTime,
      'd MMM',
    )}`;
  }
  return travelDetails;
};

export const getAllCountries = (lookupData) => {
  const preferredCountries = lookupData?.preferredCountries || [];
  const countries = lookupData?.countries || [];
  return [...preferredCountries, ...countries];
};

export const getCountryDialCode = (lookupData, phoneCountryCode, defaultValue = '1') => {
  const allCountries = getAllCountries(lookupData);
  if (!['', '??'].includes(phoneCountryCode)) {
    const countryData = allCountries.find((country) => country.iso2 === phoneCountryCode);
    if (!isEmpty(countryData)) {
      return `${countryData.dialCode}`;
    }
  }
  return defaultValue;
};

export const getSpecialSailingName = (classificationCodes, specialSailings) => {
  if (classificationCodes && specialSailings) {
    const codes = new Set(classificationCodes);
    const specialSailing = specialSailings.find((data) => codes.has(data.id));
    if (specialSailing) {
      return specialSailing.name;
    }
  }
  return '';
};

export const getSpecialSailingForClassificationCode = (classificationCode, specialSailings) => {
  const specialSailing = specialSailings.filter((data) => data.id === classificationCode);
  if (!isEmpty(specialSailing)) {
    return specialSailing[0];
  }
  return '';
};

export const getSpecialSailingRibbonColor = (classificationCode, specialSailings) => {
  if (!isEmpty(classificationCode) && !isEmpty(specialSailings)) {
    const specialSailing = getSpecialSailingForClassificationCode(classificationCode, specialSailings);
    if (specialSailing && specialSailing.showRibbon) {
      return specialSailing.ribbonColorOverride;
    }
  }
  return '';
};

export const getAddonCmsDataForCode = (code, addonCmsData) => addonCmsData.filter((cmsData) => code === cmsData.code);

export const storeFVCdataIntoSession = (availablFVCamount) => {
  setSessionStorageValue('isEligibleForFVC', false);
  if (availablFVCamount.fvcPaymentAmount > 0 && availablFVCamount.fvcDiscountAmount === 0) {
    setSessionStorageValue('isEligibleForFVC', true);
    setSessionStorageValue('availablePaymentFVC', JSON.parse(availablFVCamount?.fvcPaymentAmount || 0));
    setSessionStorageValue('FVCPaymentCoupons', availablFVCamount?.paymentFVCmapper || null);
  }
  if (availablFVCamount.fvcDiscountAmount > 0) {
    setSessionStorageValue('isEligibleForFVC', false);
  }
};

export const checkForValueType = (value) => {
  let response = false;
  if (!Number.isNaN(value)) {
    switch (value) {
      case '':
        break;
      case Array.isArray(value) && value.length === 0:
        break;
      case null:
        break;
      case 'null':
        break;
      case undefined:
        break;
      case 'undefined':
        break;
      case Object.keys(value).length === 0 && value.constructor === Object:
        break;
      case false:
      default:
        response = true;
        break;
    }
  }
  return response;
};

export const isAgentDetailsEmpty = (agentDetail) => {
  return isEmpty(agentDetail) || (isEmpty(agentDetail.agencyId) && isEmpty(agentDetail.agentId));
};

/**
 * please, consider to use `selectBookingSourcePayload` first
 */
export const getBookingSource = (currencyCode, isSignature = false, isReservation = true) => {
  const {
    agencyId = '',
    agentId = '',
    channelID,
    isMNVV,
    reservationNumber,
  } = getMnvvReservation(getCurrentSearchParams());
  const agentIdCookie = getFilterAgentId() || '';
  const agencyIdCookie = getFilterAgencyId() || '';
  const agentBC = getFilterAgentBookingChannel() || '';
  const agencyDetailsCookie = getFilterAgencyIsAvailable();
  const agency = isMNVV ? agencyId : agencyIdCookie;
  const agent = isMNVV ? agentId : agentIdCookie;
  const agentDetail = {
    ...(agency && { agencyId: agency }),
    ...(agent && { agentId: agent }),
  };

  return {
    ...(isMNVV && { bookingChannel: channelID }),
    ...(isMNVV && isReservation && { reservationNumber }),
    ...(!isMNVV && agentIdCookie && { bookingChannel: agentBC || BookingChannelTypes.fmLink }),
    ...(!isMNVV && !agentIdCookie && { bookingChannel: BookingChannelTypes.openTravel }),
    ...(currencyCode && { currencyCode: getFilterAgencyCurrencyCode() || currencyCode }),
    ...(!isSignature ? agencyDetailsCookie && { agentDetail } : { ...agentDetail }),
  };
};

export const ensureArray = (val) => {
  if (Array.isArray(val)) {
    return val;
  }

  if (typeof val === 'string') {
    if (val.indexOf(',') !== -1) {
      return val.split(',');
    }

    return [val.toString()];
  }

  return undefined;
};

export const generateRedirectUrlForFullCruiseDetails = () => {
  const baseURL = window.location.origin;
  const urlParams = window.location.search;
  const {
    fullCruiseDetails: { path: fullCruiseRoutePath },
    path,
  } = paths?.planner || {};
  const fullCruiseDetailsPath = `${path}/${fullCruiseRoutePath}`;

  return `${baseURL}${env.CONTEXT}/${fullCruiseDetailsPath}${urlParams}`;
};

export const getShipNames = (packageData) => {
  const sailings = packageData?.sortedSailings || packageData?.sailingList || [];
  const ships = [...new Set(sailings.map((item) => item?.ship?.name))];
  const shipNames = ships.concat(ships.splice(-2, 2).join(' & ')).join(', ');
  return shipNames || '';
};

export const formatHumanReadableList = (originalList = []) => {
  const list = [...new Set(originalList)];
  return list.concat(list.splice(-2, 2).join(' & ')).join(', ');
};

export const getCabinNameFromCabinCategories = (cabinsData, cabinId) => {
  if (isEmpty(cabinsData) || isEmpty(cabinId)) {
    return '';
  }
  const subMetaCabin = cabinsData?.reduce((acc, data) => {
    const sub = data?.submetas?.find((submeta) => cabinId && submeta?.code === cabinId);
    if (sub && sub?.name) {
      acc.push(sub);
    }
    return acc;
  }, []);
  return subMetaCabin?.[0]?.name || '';
};

export const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const getSafeElementId = (id) => {
  return id?.replace(/\s+/g, '-');
};
