import { type CaseReducer, createSlice, type Draft, type PayloadAction } from '@reduxjs/toolkit';

import { type TFetchVoyagesPayload } from '@/features/api/endpoints/voyage';
import { type FilteredPackage, type PackagesData, type TVoyagesResponse } from '@/infra/types/voyageInfo/package';
import { type SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import { NEW_CHOOSE_VOYAGE_ADD_TRACKED_SAILINGS } from './actionTypes';
import prepareFullState from './helpers/prepareFullState';

export type TMainPackages = Pick<PackagesData, 'defaultPackages' | 'packages' | 'sailings'> & {
  extendedPackages: FilteredPackage[];
  isLoaded?: boolean;
};

export type TFilteredPackages = {
  isLoaded?: boolean;
  packages: FilteredPackage[];
  sailings?: SailingFromPackages[];
};

export type TGenericCategories = Pick<PackagesData, 'defaultGenericCategoryCodes' | 'genericCategoryCodes'>;

export type TStoreChooseVoyageNew = TGenericCategories & {
  error?: unknown;
  filteredPackages: TFilteredPackages;
  isLoading?: boolean;
  mainPackages: TMainPackages;
  trackedSailings: string[];
  voyagesApiPayload?: TFetchVoyagesPayload;
};

export const initialState: TStoreChooseVoyageNew = {
  defaultGenericCategoryCodes: [],
  error: undefined,
  // filtered packages array after filter selection
  filteredPackages: { isLoaded: false, packages: [], sailings: [] },
  genericCategoryCodes: [],
  isLoading: false,
  mainPackages: {
    defaultPackages: [],
    extendedPackages: [],
    isLoaded: false,
    packages: [],
    sailings: [],
  },
  trackedSailings: [],
  voyagesApiPayload: undefined,
};

type TCase<P> = CaseReducer<TStoreChooseVoyageNew, PayloadAction<P>>;

const searchStartCase: TCase<TFetchVoyagesPayload> = (draft, { payload }) => {
  draft.error = undefined;
  draft.isLoading = true;
  draft.voyagesApiPayload = payload;
};

export type TFetchVoyagesSuccessResult = {
  apiPayload?: TFetchVoyagesPayload;
  response: TVoyagesResponse;
};

export type TFetchVoyagesSuccessOptions = {
  isOmitGenericCategories?: boolean;
  voyageIds?: string[];
};

export type TFetchVoyagesSuccessPayload = TFetchVoyagesSuccessResult & TFetchVoyagesSuccessOptions;

const searchSuccessCase: TCase<TFetchVoyagesSuccessPayload> = (draft, { payload }) => {
  const { apiPayload, response, ...options } = payload;
  return { ...draft, ...prepareFullState(response, apiPayload, options) };
};

const searchFailureCase: TCase<Error> = (draft, { payload }) => {
  draft.error = { message: payload.message || `${payload}`, stack: payload.stack };
  draft.isLoading = false;
  draft.voyagesApiPayload = undefined;
  draft.filteredPackages.packages = [];
  draft.filteredPackages.sailings = [];
};

type TFilteredPackagesPayload = Omit<TFilteredPackages, 'isLoaded'>;

const setFilteredPackagesCase: TCase<TFilteredPackagesPayload> = (draft, { payload }) => {
  draft.error = undefined;
  draft.filteredPackages = {
    isLoaded: true,
    packages: payload.packages,
    sailings: payload.sailings,
  };
};

const { actions, reducer } = createSlice({
  extraReducers: (builder) => {
    builder.addCase<string, PayloadAction<string[]>>(
      NEW_CHOOSE_VOYAGE_ADD_TRACKED_SAILINGS,
      (draft: Draft<TStoreChooseVoyageNew>, { payload }) => {
        draft.trackedSailings = [...draft.trackedSailings, ...payload];
      },
    );
  },
  initialState,
  name: 'chooseVoyageNew',
  reducers: {
    searchFailure: searchFailureCase,
    searchStart: searchStartCase,
    searchSuccess: searchSuccessCase,
    setFilteredPackages: setFilteredPackagesCase,
  },
});

export const { searchFailure, searchStart, searchSuccess, setFilteredPackages } = actions;

export default reducer;
