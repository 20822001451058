'use client';

import type { PartialDeep } from 'type-fest';

import { configureStore, type ThunkAction } from '@reduxjs/toolkit';
import { useDispatch, useStore } from 'react-redux';

import type { TOptional } from '@/types';

import { isProdEnv } from '@/helpers/util/env';

import { wrapRootReducer } from './prefill/stuff';
import appReducer from './reducer';

export type RootAction = Parameters<typeof appReducer>[1];
export type RootState = ReturnType<typeof appReducer>;
export type AppStore = ReturnType<typeof configureStore<RootState>>;
export type AppDispatch = AppStore['dispatch'];
export type AppGetState = AppStore['getState'];
export type AppThunk = ThunkAction<void, RootState, undefined, RootAction>;

const rootReducer = wrapRootReducer<RootState, RootAction>(appReducer);
const isProduction = isProdEnv();

export let rootStore: TOptional<AppStore> = undefined;

export const setupStore = (preloadedState?: PartialDeep<RootState>, options: { reconfigure?: boolean } = {}) => {
  if (!rootStore || options.reconfigure) {
    rootStore = configureStore({
      devTools: !isProduction && { name: 'VV Book', trace: true, traceLimit: 25 },
      middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
          immutableCheck: {
            warnAfter: 80,
          },
        }),
      preloadedState,
      reducer: rootReducer,
    });
  }
  return rootStore;
};

export const useAppStore: () => AppStore = useStore;
export const useAppDispatch: () => AppDispatch = useDispatch;
export * from './useAppSelector';
