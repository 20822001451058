import isEmpty from 'lodash/isEmpty';

import type { LookupSlice } from '@/ducks/common/lookup/types';
import type { SettingsSlice } from '@/ducks/common/settings';
import type { MnvvData } from '@/ducks/mnvv/types';
import type { GQLClientInfoLookup } from '@/helpers/api/graphql';
import type { CruiseCommonSlice, TStoreVoyageSearch, VoyageDataResponse } from '@/infra/types/voyageInfo/package';
import type { RootState } from '@/store';

import { initialState as accessKeysDefaultState } from '@/ducks/accessKeys/accessKeys';
import { initialCruisesCommonState } from '@/ducks/cruisesCommon/slice';
import { calculateAdditonalVoyagesData } from '@/ducks/cruisesCommon/utils';
import { getFilterValues } from '@/ducks/filters/utils';
import { populateDefaultFilter } from '@/ducks/filtersOptions/helpers';
import prepareFullState from '@/ducks/pages/chooseVoyage/helpers/prepareFullState';
import { selectFetchVoyagesSuccessOptions, selectMakeFilteredPackages } from '@/ducks/pages/chooseVoyage/selectors';
import {
  initialState as chooseVoyageDefaultState,
  type TFetchVoyagesSuccessResult,
  type TStoreChooseVoyageNew,
} from '@/ducks/pages/chooseVoyage/slice';
import { initialState as promoBannersInitialState, type TStorePromoBanners } from '@/features/promoBanners/slice';
import { initialState as strikethroughInitialState, type TStoreStrikethrough } from '@/features/strikethrough/slice';
import { type TFetchAgentDetails } from '@/helpers/api/resources';
import { getCookie } from '@/helpers/ssr/cookies';
import { type TFiltersOptions } from '@/infra/types/common/filters';
import { type TAgencyAgentResponse } from '@/infra/types/fmlink/agent';
import { type TStoreTravelInsurance, type TTravelInsuranceResponse } from '@/infra/types/summary';
import { type TSailingDataResponse, type TStoreSailingData } from '@/infra/types/voyageInfo/sailing';
import { type TFetchableData } from '@/store/fetchableParts/types';
import { type TOptional } from '@/types/common';

const buildInitialState = (args: TFetchableData, searchParams: URLSearchParams, prevState?: RootState): RootState => {
  const initialState = {
    ...prevState,
    accessKeys: prevState?.accessKeys || accessKeysDefaultState,
    agentData: mergeAgentData(args.agentAgencyDetails, prevState),
    common: {
      ...prevState?.common,
      clientIp: args.clientIp ?? prevState?.common?.clientIp,
      geoLocation: args.geoLocation ?? prevState?.common?.geoLocation,
      lookup: mergeLookup(args.lookup, args.clientInfo, prevState) ?? ({} as LookupSlice),
      resources: args.resources ?? prevState?.common?.resources,
      settings: mergeSettings(args.settings, args.mnvvData, prevState) ?? ({} as SettingsSlice),
    },
    cruisesCommon:
      mergeCruisesCommon(args.voyageData, prevState) ?? ({ ...initialCruisesCommonState } as CruiseCommonSlice),
    filtersOptions: mergeFiltersOptions(args, searchParams, prevState),
    mgm: {
      ...prevState?.mgm,
      isMgm: mergeIsMgm(args.settings, prevState),
    },
    mnvvData: args.mnvvData ?? prevState?.mnvvData,
    promoBanners: mergePromoBanners(args.promoBanners, prevState),
    quickSearchItems: args.quickSearch ?? prevState?.quickSearchItems,
    strikethrough: mergeStrikethrough(args.strikethrough, prevState),
    voyagePlanner: {
      ...prevState?.voyagePlanner,
      itineraryResults: {
        ...prevState?.voyagePlanner?.itineraryResults,
        voyageSearch: mergeVoyageSearch(args.voyageData, prevState),
      },
      sailorDetails: {
        ...prevState?.voyagePlanner?.sailorDetails,
        travelInsuranceFlyoutData: mergeTravelInsurance(args.travelInsurance, prevState),
      },
      summary: {
        ...prevState?.voyagePlanner?.summary,
        sailingData: mergeSailings(args.sailings, prevState),
      },
    },
  } as unknown as RootState;

  initialState.filters = getFilterValues({ getCookie, searchParams, state: initialState }, true);
  initialState.chooseVoyageNew = mergeChooseVoyageNew(args.voyages, prevState, initialState);

  return initialState;
};

const mergeAgentData = (
  agentAgencyDetails: TOptional<TFetchAgentDetails>,
  prevState: TOptional<RootState>,
): TOptional<TAgencyAgentResponse> => agentAgencyDetails?.response ?? prevState?.agentData;

const mergeChooseVoyageNew = (
  voyages: TOptional<TFetchVoyagesSuccessResult>,
  prevState: TOptional<RootState>,
  nextState: RootState,
): TStoreChooseVoyageNew => {
  const { apiPayload, response } = voyages || {};
  let branch = prevState?.chooseVoyageNew ?? chooseVoyageDefaultState;
  if (response) {
    branch = { ...branch, ...prepareFullState(response, apiPayload, selectFetchVoyagesSuccessOptions(nextState)) };
    const state = { ...nextState, chooseVoyageNew: branch };
    branch.filteredPackages = { isLoaded: true, ...selectMakeFilteredPackages(state) };
  }
  return branch;
};

const mergeCruisesCommon = (
  voyageData: TOptional<VoyageDataResponse>,
  prevState: TOptional<RootState>,
): TOptional<CruiseCommonSlice> =>
  voyageData
    ? {
        ...initialCruisesCommonState,
        voyagesData: voyageData,
        ...calculateAdditonalVoyagesData(voyageData),
      }
    : prevState?.cruisesCommon;

const mergeFiltersOptions = (
  args: TFetchableData,
  searchParams: URLSearchParams,
  prevState: TOptional<RootState>,
): TOptional<TFiltersOptions> =>
  args.lookup || args.settings || args.voyageData || !prevState?.filtersOptions
    ? {
        ...prevState?.filtersOptions,
        ...populateDefaultFilter({
          lookup: mergeLookup(args.lookup, args.clientInfo, prevState) ?? ({} as LookupSlice),
          searchParams,
          settings: mergeSettings(args.settings, args.mnvvData, prevState) ?? ({} as SettingsSlice),
          voyagesData:
            args.voyageData ??
            prevState?.voyagePlanner?.itineraryResults?.voyageSearch?.data ??
            ({} as VoyageDataResponse),
        }),
      }
    : prevState?.filtersOptions;

const mergeIsMgm = (settings: TOptional<SettingsSlice>, prevState: TOptional<RootState>): TOptional<boolean> =>
  settings ? settings?.memberGetMember : prevState?.mgm?.isMgm;

const mergeLookup = (
  lookup: TOptional<LookupSlice>,
  clientInfo: TOptional<GQLClientInfoLookup>,
  prevState: TOptional<RootState>,
): TOptional<LookupSlice> =>
  clientInfo ? { ...(lookup ?? prevState?.common?.lookup)!, ...clientInfo } : (lookup ?? prevState?.common?.lookup);

const mergePromoBanners = (
  promoBanners: TOptional<TStorePromoBanners>,
  prevState: TOptional<RootState>,
): TOptional<TStorePromoBanners> =>
  promoBanners ? { ...promoBannersInitialState, isLoaded: true, ...promoBanners } : prevState?.promoBanners;

const mergeSettings = (
  settings: TOptional<SettingsSlice>,
  mnvvData: TOptional<MnvvData>,
  prevState: TOptional<RootState>,
): TOptional<SettingsSlice> =>
  settings
    ? { ...settings, ...(!isEmpty(mnvvData ?? prevState?.mnvvData) ? { minCabinOccupancy: 2 } : undefined) }
    : prevState?.common?.settings;

const mergeStrikethrough = (
  strikethrough: TOptional<TStoreStrikethrough>,
  prevState: TOptional<RootState>,
): TOptional<TStoreStrikethrough> =>
  strikethrough ? { ...strikethroughInitialState, isLoaded: true, ...strikethrough } : prevState?.strikethrough;

const mergeVoyageSearch = (
  voyageData: TOptional<VoyageDataResponse>,
  prevState: TOptional<RootState>,
): TOptional<TStoreVoyageSearch> =>
  voyageData
    ? { data: voyageData, error: {}, loading: false }
    : (prevState?.voyagePlanner?.itineraryResults?.voyageSearch as TStoreVoyageSearch);

const mergeTravelInsurance = (
  travelInsurance: TOptional<TTravelInsuranceResponse>,
  prevState: TOptional<RootState>,
): TOptional<TStoreTravelInsurance> =>
  travelInsurance
    ? { data: travelInsurance, error: {}, isLoaded: true }
    : (prevState?.voyagePlanner?.sailorDetails?.travelInsuranceFlyoutData as TStoreTravelInsurance);

const mergeSailings = (
  sailings: TOptional<TSailingDataResponse>,
  prevState: TOptional<RootState>,
): TOptional<TStoreSailingData> =>
  sailings
    ? { data: sailings, error: {}, isLoaded: true }
    : (prevState?.voyagePlanner?.summary?.sailingData as TStoreSailingData);

export default buildInitialState;
