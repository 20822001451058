import isEmpty from 'lodash/isEmpty';
import { createSelector } from 'reselect';

import type { RootState } from '@/store';

import { LOCK_IT_IN_CABINS } from '@/containers/VoyagePlanner/Summary/constants';
import createMemoSelector from '@/helpers/createMemoSelector';
import { getGrandTotal, getPriceBreakdownProps } from '@/helpers/data/mappers/Summary';

const emptyObj = {};

// Direct selectors:

export const selectSummary = (state: RootState) => state?.voyagePlanner?.summary;

export const selectCabinHold = (state: RootState) => selectSummary(state)?.cabinHold;

export const selectCabinHoldData = (state: RootState) => selectCabinHold(state)?.data;

export const selectCabinHoldDueDateTime = (state: RootState) => selectCabinHoldData(state)?.holdData?.[0]?.dueDateTime;

export const selectIsPayInFullPromoApplied = (state: RootState): boolean =>
  selectSummary(state)?.isPayInFullPromoApplied || false;

export const selectCalculateInvoice = (state: RootState) => selectSummary(state)?.calculateInvoice;
export const selectCalculateInvoiceData = (state: RootState) => selectCalculateInvoice(state)?.data || emptyObj;
export const selectCalculateInvoiceError = (state: RootState) => selectCalculateInvoice(state)?.error ?? emptyObj;
export const selectCalculateInvoiceErrorCategoryCode = (state: RootState) =>
  selectCalculateInvoiceError(state)?.categoryCode || null;
export const selectCalculateInvoiceErrorSubCategoryCode = (state: RootState) =>
  selectCalculateInvoiceError(state)?.subCategoryCode || null;
export const selectCalculateInvoiceIsLoading = (state: RootState) =>
  selectCalculateInvoice(state)?.isLoading || isEmpty(selectCalculateInvoiceData(state));

export const selectIsCalculateInvoiceLoaded = (state: RootState) => selectCalculateInvoice(state)?.isLoaded;

export const selectCabinInvoices = (state: RootState) => selectCalculateInvoiceData(state)?.cabinInvoices;

export const selectAddOnPriceDetails = (state: RootState) => selectCabinInvoices(state)?.[0]?.addOnPriceDetails;

export const selectBookingPriceDetails = (state: RootState) => selectCabinInvoices(state)?.[0]?.bookingPriceDetails;

export const selectInvoiceCurrencyCode = (state: RootState) => selectBookingPriceDetails(state)?.currencyCode;

export const selectCalculateInvoiceCabinNumber = (state: RootState) => selectCabinInvoices(state)?.[0]?.cabinNumber;

export const selectGuestCount = (state: RootState) => selectCabinInvoices(state)?.[0]?.guestCount;

export const selectFullPayPrice = (state: RootState) => selectCabinInvoices(state)?.[0]?.fullPayPrice;

export const selectPaymentSchedule = (state: RootState) => selectCalculateInvoiceData(state)?.paymentSchedule;

export const selectCabinInvoiceCabinType = (state: RootState) => selectCabinInvoices(state)?.[0]?.cabinTypeCode;
export const selectCabinInvoiceCabinName = (state: RootState) => selectCabinInvoices(state)?.[0]?.name;

// Memoized selectors:

export const selectCabinInvoiceAllowCabinHold = createMemoSelector(
  selectCalculateInvoiceCabinNumber,
  (cabinNumber) => !!cabinNumber && !LOCK_IT_IN_CABINS.includes(cabinNumber!),
);

export const selectPriceBreakdown = createMemoSelector(selectCalculateInvoiceData, (invoice) => {
  if (!invoice.cabinInvoices || !invoice.paymentSchedule) return;

  return getPriceBreakdownProps(invoice.cabinInvoices, invoice.paymentSchedule);
});

export const selectTripInsuranceAmount = createMemoSelector(
  selectBookingPriceDetails,
  selectFullPayPrice,
  selectIsPayInFullPromoApplied,
  (bookingPriceDetails, fullPayPrice, isPayInFullPromoApplied) =>
    (isPayInFullPromoApplied ? fullPayPrice : bookingPriceDetails)?.tripInsurance ?? 0,
);
export const selectIsPayInFullPromoAvailable = createSelector(selectCabinInvoices, (cabinInvoices) => {
  const cabinInvoice = cabinInvoices?.[0];
  const fullPayGrandTotalAmount = getGrandTotal(cabinInvoice, 'fullPayPrice');
  const bookingPriceGrandTotalAmount = getGrandTotal(cabinInvoice, 'bookingPriceDetails');
  return fullPayGrandTotalAmount > 0 && fullPayGrandTotalAmount < bookingPriceGrandTotalAmount;
});
