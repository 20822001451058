import { type AppRouterInstance } from 'next/dist/shared/lib/app-router-context.shared-runtime';
import { type ReadonlyURLSearchParams } from 'next/navigation';

import { JL } from 'jsnlog';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isObjectLike from 'lodash/isObjectLike';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import qs from 'query-string';

import getCurrentSearchParams from '@/helpers/url/getCurrentSearchParams';
import searchParamsAsObject from '@/helpers/url/searchParamsAsObject';
import { type TOptional } from '@/types/common';

let nextRouter: AppRouterInstance;
let nextPathname: string;
let nextSearchParams: URLSearchParams;

export const setRouter = (router: AppRouterInstance) => {
  nextRouter = router;
};
export const setPathname = (pathname: string) => {
  nextPathname = pathname;
};
export const setSearchParams = (searchParams: URLSearchParams | ReadonlyURLSearchParams) => {
  nextSearchParams = searchParams as URLSearchParams;
};

export const getRouter = () => {
  return nextRouter;
};
export const getPathname = () => {
  return nextPathname;
};
export const getURLSearchParams = () => {
  return nextSearchParams;
};

/**
 * @deprecated use `getCurrentSearchParams` instead
 */
export const getSearchParams = (options) => {
  return qs.parse(nextSearchParams?.toString(), options);
};
export const getSearchParam = (paramName) => {
  return nextSearchParams?.get(paramName);
};

export const matches = (path) => nextPathname.includes(path);

export const buildUrlPath = ({ hash, pathname = '', query }) => {
  const hashPath = hash ? `#${hash}` : '';
  const searchStr = query ? qs.stringify(query) : '';
  const searchPath = searchStr ? `?${searchStr}` : '';
  const urlPath = `${pathname}${searchPath}${hashPath}`;

  return urlPath;
};

const redirectToExternal = ({ hash, pathname, query }) => {
  const urlPath = buildUrlPath({ hash, pathname, query });

  if (!urlPath) {
    console.warn('Invalid external url to redirect', urlPath);
    return;
  }

  window.location.href = urlPath;
};

export const redirect = (
  pathname,
  params = {},
  { isHref = false, keepSearch = false, replace = false, routerArgs = {} } = {},
  hashLocation = '',
) => {
  const redirectParams = {
    hash: hashLocation,
    pathname,
    query: keepSearch
      ? {
          ...getSearchParams(),
          ...params,
        }
      : params,
  };

  if (isHref) {
    redirectToExternal(redirectParams);
    return;
  }

  const messageDetail = {
    message: 'redirect called',
    messageCode: '5001',
    severity: 'INFO',
  };
  JL('BV').info({
    message: redirectParams,
    messageDetail,
  });

  const urlPath = buildUrlPath(redirectParams);

  if (replace) {
    nextRouter.replace(urlPath, routerArgs);
  } else {
    nextRouter.push(urlPath, routerArgs);
  }
};

export type TNavigationOptions = {
  asHistory?: boolean;
  asPush?: boolean;
};

export type TUpdateSearchParamsArgs = TNavigationOptions & {
  searchParams?: URLSearchParams;
  searchParamsUpdater?: (searchParams: URLSearchParams) => void;
};

export const updateSearchParams = ({
  asHistory,
  asPush,
  searchParams,
  searchParamsUpdater,
}: TUpdateSearchParamsArgs): TOptional<URLSearchParams> => {
  const makeUpdatedSearchParams = () => {
    if (searchParamsUpdater) {
      const params = new URLSearchParams(window.location.search);
      const before = searchParamsAsObject(params);
      searchParamsUpdater(params);
      const after = searchParamsAsObject(params);
      if (!isEqual(before, after)) return params;
    }
  };

  const params = searchParams || makeUpdatedSearchParams();
  if (params) {
    const query = params.toString();
    if (asHistory) {
      const { pathname } = window.location;
      history[asPush ? 'pushState' : 'replaceState'](null, '', `${pathname}${query ? `?${query}` : ''}`);
    } else {
      getRouter()[asPush ? 'push' : 'replace'](`?${query}`, { scroll: false });
    }
  }
  return params;
};

/**
 * Change current search query without changing pathname with history.replace()
 */
export const updateSearch = (query, { nextjs = {}, nprogress = {} } = {}) => {
  const compactQuery = clearEmptyParams(query);

  // TODO: remove this when we have a better solution
  const newQueryString = new URLSearchParams(compactQuery).toString();
  const currentQueryString = getCurrentSearchParams().toString();
  if (newQueryString !== currentQueryString) {
    return nextRouter.replace('?' + newQueryString, { ...nextjs, scroll: false }, nprogress);
  }
};

const clearEmptyParams = (query) =>
  omitBy(query, (value) => (isObjectLike(value) || isString(value)) && isEmpty(value));

/**
 * Replace specified param in search query with Router.replace()
 */
export const replaceSearchParam = (name, value, force) => {
  if (force || nextSearchParams.get(name)) {
    const messageDetail = {
      message: 'replace called',
      messageCode: '5001',
      severity: 'INFO',
    };
    JL('BV').info({
      message: { name, value },
      messageDetail,
    });

    updateSearch({ ...getSearchParams(), [name]: value });
  }
};

/**
 * Remove particular params from search query with history.replace()
 */
export const removeParticularSearchParams = (paramNames) => {
  return updateSearch(omit(searchParamsAsObject(getCurrentSearchParams()), paramNames));
};

export const removeSearchParamsExcept = (paramNames) => {
  return updateSearch(pick(searchParamsAsObject(getCurrentSearchParams()), paramNames));
};
