import { type TFetchVoyagesPayload } from '@/features/api/endpoints/voyage';
import { FiltersSortType } from '@/infra/types/common/filters';
import { type Package, type TVoyagesResponse } from '@/infra/types/voyageInfo/package';

import { getSailingsFromPackages } from '../getters';
import { groupPackagesWithSameName } from '../groupPackagesWithSameNames';
import { type TFetchVoyagesSuccessOptions, type TMainPackages, type TStoreChooseVoyageNew } from '../slice';
import { sortSailings } from '../utility';

const prepareFullState = (
  response: TVoyagesResponse,
  apiPayload?: TFetchVoyagesPayload,
  options?: TFetchVoyagesSuccessOptions,
): Partial<TStoreChooseVoyageNew> => {
  const { isOmitGenericCategories, voyageIds } = options || {};
  const { defaultGenericCategoryCodes, defaultPackages, genericCategoryCodes, packages } = response || {};

  const sailings = getSailingsFromPackages(packages);

  const mainPackages: TMainPackages = {
    defaultPackages: defaultPackages?.length
      ? (sortSailings(defaultPackages, FiltersSortType.date) as Package[])
      : defaultPackages || [],
    extendedPackages: groupPackagesWithSameName(packages),
    isLoaded: true,
    packages: packages || [],
    sailings: voyageIds?.length ? sailings.filter((sailing) => voyageIds.includes(sailing.id)) : sailings,
  };

  return {
    ...(!isOmitGenericCategories ? { defaultGenericCategoryCodes, genericCategoryCodes } : undefined),
    error: undefined,
    isLoading: false,
    mainPackages,
    ...(apiPayload ? { voyagesApiPayload: apiPayload } : undefined),
  };
};

export default prepareFullState;
