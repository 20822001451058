import { type CaseReducer, createSlice, type PayloadAction } from '@reduxjs/toolkit';
import defaults from 'lodash/defaults';

import { THUNK_PREFIXES as PROFILE_PREFIXES } from '@/features/profile/types';
import getThunkStates from '@/helpers/getThunkStates';
import { type TOptional } from '@/types/common';

import type { TProfileArg } from './helpers/convertProfileDataToPrimarySailor';
import type { PrevSubscriptionsSailorInfo, TFieldName, TSailorInfo, TSailorInvalidity } from './types';

import { PRIMARY_SAILOR_DEFAULT_VALUES } from './constants';
import convertProfileDataToPrimarySailor from './helpers/convertProfileDataToPrimarySailor';

export type TStoreTravelParty = {
  addonVoyageProtection?: boolean;
  hasIncludedGratuities?: boolean;
  invalidSecondarySailors: TSailorInvalidity[];
  isCompletedFlow?: boolean;
  isSecondarySailorInspecting?: boolean;
  prevSubscriptions: PrevSubscriptionsSailorInfo | null;
  primarySailor: TSailorInfo;
  primarySailorErrors: TFieldName[];
  removedSecondarySailors: TSailorInfo[];
  sailorSlotCount: number;
  secondarySailors: TSailorInfo[];
  secondarySailorsOnEdit: number[];
};

const initialState: TStoreTravelParty = {
  addonVoyageProtection: undefined,
  hasIncludedGratuities: undefined,
  invalidSecondarySailors: [],
  isSecondarySailorInspecting: undefined,
  prevSubscriptions: null,
  primarySailor: {} as TSailorInfo,
  primarySailorErrors: [],
  removedSecondarySailors: [],
  sailorSlotCount: 0,
  secondarySailors: [],
  secondarySailorsOnEdit: [],
};

const performSetSecondarySailor = (
  sailorNumber: number,
  sailorDetails: TSailorInfo,
  secondarySailors: TSailorInfo[],
): TSailorInfo[] => {
  const index = secondarySailors.findIndex((item) => item.sailorNumber === sailorNumber);
  const sailor = { sailorNumber, ...sailorDetails };
  return (
    index !== -1
      ? [...secondarySailors.slice(0, index), sailor, ...secondarySailors.slice(index + 1)]
      : [...secondarySailors, sailor]
  ).sort((a, b) => (a.sailorNumber || 0) - (b.sailorNumber || 0));
};

const performClearSecondarySailor = (sailorNumber: number, secondarySailors: TSailorInfo[]): TSailorInfo[] =>
  secondarySailors
    .filter((item) => item.sailorNumber !== sailorNumber)
    .map((item) =>
      (item.sailorNumber || 0) < sailorNumber ? item : { ...item, sailorNumber: (item.sailorNumber || 0) - 1 },
    );

const performAdjustSecondarySailors = (
  slotCount: number,
  prevSlotCount: number,
  secondarySailors: TSailorInfo[],
  removedSecondarySailors: TSailorInfo[],
) => {
  if (slotCount >= 0 && slotCount !== prevSlotCount) {
    const maxSailorNumber = Math.max(0, ...secondarySailors.map(({ sailorNumber }): number => sailorNumber || 0));
    const emptySlots = prevSlotCount - maxSailorNumber;
    const change = slotCount - prevSlotCount;
    if (change < 0) {
      const moveCount = -change - emptySlots;
      return moveCount > 0
        ? {
            removedSecondarySailors: [
              ...secondarySailors.slice(-moveCount).map(({ sailorNumber, ...rest }) => rest),
              ...removedSecondarySailors,
            ],
            sailorSlotCount: slotCount,
            secondarySailors: secondarySailors.slice(0, -moveCount),
          }
        : { sailorSlotCount: slotCount };
    }
    return removedSecondarySailors.length
      ? {
          removedSecondarySailors: removedSecondarySailors.slice(change),
          sailorSlotCount: slotCount,
          secondarySailors: [
            ...secondarySailors,
            ...removedSecondarySailors
              .slice(0, change)
              .map((item, i) => ({ ...item, sailorNumber: prevSlotCount + i + 1 })),
          ],
        }
      : { sailorSlotCount: slotCount };
  }
  return undefined;
};

/**
 * Case Reducers
 */

const updatePrimarySailorCase: CaseReducer<TStoreTravelParty, PayloadAction<Partial<TSailorInfo>>> = (
  stateDraft,
  { payload: sailor },
) => {
  if (sailor.email && sailor.email !== stateDraft.primarySailor.email) {
    stateDraft.prevSubscriptions = initialState.prevSubscriptions;
  }

  stateDraft.primarySailor = { ...stateDraft.primarySailor, ...sailor } as TSailorInfo;
  stateDraft.primarySailorErrors = stateDraft.primarySailorErrors?.filter((key) => !Object.keys(sailor).includes(key));
};

type TSetSecondarySailor = { sailor: TSailorInfo; sailorNumber: number };
const setSecondarySailorCase: CaseReducer<TStoreTravelParty, PayloadAction<TSetSecondarySailor>> = (
  stateDraft,
  { payload },
) => {
  const { sailor, sailorNumber } = payload || {};
  stateDraft.secondarySailors = performSetSecondarySailor(sailorNumber, sailor, stateDraft.secondarySailors);
};

const clearSecondarySailorCase: CaseReducer<TStoreTravelParty, PayloadAction<number>> = (
  stateDraft,
  { payload: sailorNumber },
) => {
  stateDraft.secondarySailors = performClearSecondarySailor(sailorNumber, stateDraft.secondarySailors);
};

const adjustSecondarySailorsCase: CaseReducer<TStoreTravelParty, PayloadAction<number>> = (
  stateDraft,
  { payload: slotCount },
) => {
  const { removedSecondarySailors, sailorSlotCount, secondarySailors } = stateDraft;
  const refinement = performAdjustSecondarySailors(
    slotCount,
    sailorSlotCount,
    secondarySailors,
    removedSecondarySailors,
  );
  if (refinement) return { ...stateDraft, ...refinement };
};

const setPrimarySailorErrorsCase: CaseReducer<TStoreTravelParty, PayloadAction<TFieldName[]>> = (
  stateDraft,
  { payload: errors },
) => {
  stateDraft.primarySailorErrors = errors;
};

const clearPrimarySailorErrorsCase: CaseReducer<TStoreTravelParty, PayloadAction<undefined>> = (stateDraft) => {
  stateDraft.primarySailorErrors = [];
};

const setCompletedFlowCase: CaseReducer<TStoreTravelParty, PayloadAction<undefined>> = (stateDraft) => {
  stateDraft.isCompletedFlow = true;
};
const resetTravelPartyCase: CaseReducer<TStoreTravelParty, PayloadAction<undefined>> = () => ({ ...initialState });

const prefillTravelPartyCase: CaseReducer<TStoreTravelParty, PayloadAction<TStoreTravelParty>> = (
  stateDraft,
  { payload: state },
) => ({ ...initialState, ...state });

const setSecondarySailorEditingCase: CaseReducer<TStoreTravelParty, PayloadAction<number>> = (
  stateDraft,
  { payload: sailorNumber },
) => {
  stateDraft.secondarySailorsOnEdit.push(sailorNumber);
};

const unsetSecondarySailorEditingCase: CaseReducer<TStoreTravelParty, PayloadAction<number>> = (
  stateDraft,
  { payload: sailorNumber },
) => {
  stateDraft.secondarySailorsOnEdit = stateDraft.secondarySailorsOnEdit.filter((num) => num !== sailorNumber);
};

const startInspectingSecondarySailorEditingCase: CaseReducer<TStoreTravelParty, PayloadAction<undefined>> = (
  stateDraft,
) => {
  stateDraft.isSecondarySailorInspecting = true;
};

const endInspectingSecondarySailorEditingCase: CaseReducer<TStoreTravelParty, PayloadAction<undefined>> = (
  stateDraft,
) => {
  stateDraft.isSecondarySailorInspecting = false;
};

const setInvalidSecondarySailorsCase: CaseReducer<TStoreTravelParty, PayloadAction<TSailorInvalidity[]>> = (
  stateDraft,
  { payload: invalidSailors },
) => {
  stateDraft.invalidSecondarySailors = invalidSailors || [];
};

const unsetInvalidSecondarySailorCase: CaseReducer<TStoreTravelParty, PayloadAction<number>> = (
  stateDraft,
  { payload: sailorNumber },
) => {
  stateDraft.invalidSecondarySailors = stateDraft.invalidSecondarySailors.filter(
    (invalidity) => invalidity.sailorNumber !== sailorNumber,
  );
};

const resetInvalidSecondarySailorsCase: CaseReducer<TStoreTravelParty, PayloadAction<undefined>> = (stateDraft) => {
  stateDraft.invalidSecondarySailors = [];
};

const setAddonVoyageProtectionCase: CaseReducer<TStoreTravelParty, PayloadAction<TOptional<boolean>>> = (
  stateDraft,
  { payload },
) => {
  stateDraft.addonVoyageProtection = !!payload;
};

const setHasIncludedGratuitiesCase: CaseReducer<TStoreTravelParty, PayloadAction<TOptional<boolean>>> = (
  stateDraft,
  { payload },
) => {
  stateDraft.hasIncludedGratuities = !!payload;
};

const accountsDashboardProfileDataSuccessCase: CaseReducer<TStoreTravelParty, PayloadAction<TProfileArg>> = (
  stateDraft,
  { payload: profile },
) => {
  const defaultValues = defaults(stateDraft.primarySailor, PRIMARY_SAILOR_DEFAULT_VALUES);
  stateDraft.primarySailor = convertProfileDataToPrimarySailor(profile, defaultValues);
  stateDraft.primarySailorErrors = [];
};

// There is a circular dependency in the file @/helpers/api/core (through store). So, use the thunk states names, not the thunk itself
const fetchUserProfile = getThunkStates(PROFILE_PREFIXES.fetchUserProfile);

const setPrevSubscriptionsCase: CaseReducer<TStoreTravelParty, PayloadAction<PrevSubscriptionsSailorInfo>> = (
  stateDraft,
  { payload: prevSubscriptions },
) => {
  stateDraft.prevSubscriptions = prevSubscriptions;
};

/* eslint-disable perfectionist/sort-objects */
const { actions, reducer } = createSlice({
  name: 'travelParty',
  initialState: initialState,
  reducers: {
    adjustSecondarySailors: adjustSecondarySailorsCase,
    clearPrimarySailorErrors: clearPrimarySailorErrorsCase,
    clearSecondarySailor: clearSecondarySailorCase,
    endInspectingSecondarySailorEditing: endInspectingSecondarySailorEditingCase,
    prefillTravelParty: prefillTravelPartyCase,
    resetInvalidSecondarySailors: resetInvalidSecondarySailorsCase,
    resetTravelParty: resetTravelPartyCase,
    setAddonVoyageProtection: setAddonVoyageProtectionCase,
    setInvalidSecondarySailors: setInvalidSecondarySailorsCase,
    setPrimarySailorErrors: setPrimarySailorErrorsCase,
    setCompletedFlow: setCompletedFlowCase,
    setSecondarySailor: setSecondarySailorCase,
    setSecondarySailorEditing: setSecondarySailorEditingCase,
    startInspectingSecondarySailorEditing: startInspectingSecondarySailorEditingCase,
    unsetInvalidSecondarySailor: unsetInvalidSecondarySailorCase,
    unsetSecondarySailorEditing: unsetSecondarySailorEditingCase,
    updatePrimarySailor: updatePrimarySailorCase,
    setPrevSubscriptions: setPrevSubscriptionsCase,
    setHasIncludedGratuities: setHasIncludedGratuitiesCase,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserProfile.fulfilled, accountsDashboardProfileDataSuccessCase);
  },
});
/* eslint-enable perfectionist/sort-objects */

export const {
  adjustSecondarySailors,
  clearPrimarySailorErrors,
  clearSecondarySailor,
  endInspectingSecondarySailorEditing,
  prefillTravelParty,
  resetInvalidSecondarySailors,
  resetTravelParty,
  setAddonVoyageProtection,
  setCompletedFlow,
  setHasIncludedGratuities,
  setInvalidSecondarySailors,
  setPrevSubscriptions,
  setPrimarySailorErrors,
  setSecondarySailor,
  setSecondarySailorEditing,
  startInspectingSecondarySailorEditing,
  unsetInvalidSecondarySailor,
  unsetSecondarySailorEditing,
  updatePrimarySailor,
} = actions;

export default reducer;
