import type { RootState } from '@/store';

const emptyArr: string[] = [];

export const selectFilters = (state: RootState) => state?.filters?.values;
export const selectFiltersDependencies = (state: RootState) => state?.filters?.dependencies;

export const selectAccessible = (state: RootState) => selectFilters(state)?.accessible;
export const selectCabins = (state: RootState) => selectFilters(state)?.cabins;
export const selectCabinType = (state: RootState) => selectFilters(state)?.cabinType;
export const selectCurrencyCode = (state: RootState) => selectFilters(state)?.currencyCode;
export const selectPriceType = (state: RootState) => selectFilters(state)?.priceType;
export const selectSailors = (state: RootState) => selectFilters(state)?.sailors;
export const selectHomePorts = (state: RootState) => selectFilters(state)?.homePorts;
export const selectDurations = (state: RootState) => selectFilters(state)?.durations;
export const selectMaxPrice = (state: RootState) => selectFilters(state)?.priceMax;
export const selectMinPrice = (state: RootState) => selectFilters(state)?.priceMin;
export const selectIsPriceRangeEmpty = (state: RootState) => !selectMaxPrice(state) && !selectMinPrice(state);
export const selectShipList = (state: RootState) => selectFilters(state)?.ships;
export const selectSortType = (state: RootState) => selectFilters(state)?.sortType;
export const selectToDate = (state: RootState) => selectFilters(state)?.dateTo;
export const selectFromDate = (state: RootState) => selectFilters(state)?.dateFrom;
export const selectSelectedItineraries = (state: RootState) => selectFilters(state)?.destPackages;
export const selectSelectedPortsOfCall = (state: RootState) => selectFilters(state)?.destPorts;
export const selectSelectedItinerariesRegions = (state: RootState) => selectFilters(state)?.destPackagesRegions;
export const selectSelectedPortsOfCallRegions = (state: RootState) => selectFilters(state)?.destPortsRegions;
export const selectWeekend = (state: RootState) => selectFilters(state)?.weekend;
export const selectMetaType = (state: RootState) => selectFilters(state)?.metaType;
export const selectVoyageIds = (state: RootState) => selectFilters(state)?.voyageIds;
export const selectNodes = (_state: RootState) => emptyArr;
