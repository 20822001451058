import { createSelector } from '@reduxjs/toolkit';
import { max } from 'date-fns';
import find from 'lodash/find';

import type { TFiltersDuration, TFiltersOptions } from '@/infra/types/common/filters';
import type { RootState } from '@/store';

import { selectLookup } from '@/ducks/common/selectors';
import { selectMnvvVoyage } from '@/ducks/mnvv/selectors';
import { calculateMaxDate, getMnvvReservation } from '@/ducks/mnvv/utils';
import { currentNYTime, format, parse } from '@/helpers/util/dateUtil';
import { type TISODate } from '@/infra/types/common';

export const getFiltersOptions = (state: RootState) => state.filtersOptions as TFiltersOptions;
export const getDefaultFilters = (state: RootState) => getFiltersOptions(state).defaultFilters;
export const getMaxSailors = (state: RootState) =>
  state.filtersOptions.maxCabinOccupancy * state.filtersOptions.maxCabinCount;

export const selectDefaultFilterPriceType = (state: RootState) => getDefaultFilters(state)?.priceType;
export const selectDefaultFilterSortType = (state: RootState) => getDefaultFilters(state)?.sortType;
export const selectDefaultFilterSailors = (state: RootState) => getDefaultFilters(state)?.sailors;

export const selectFiltersOptionsDefaultStartDate = createSelector(
  getFiltersOptions,
  ({ defaultStartDate, minDate }): TISODate => format(max([parse(defaultStartDate)!, parse(minDate)!])),
);

export const selectFiltersOptionsDefaultEndDate = createSelector(
  getFiltersOptions,
  ({ defaultEndDate, minDate }): TISODate => format(max([parse(defaultEndDate)!, parse(minDate)!])),
);

export const getRegions = (state: RootState) => state.filtersOptions.regions;
export const getRegionById = (state: RootState, id: string) => find(getRegions(state), { id });
export const getRegionByPkgCode = (state: RootState, pkgCode: string) =>
  find(getRegions(state), (rgn) => !!find(rgn.packages, { id: pkgCode }));
export const getRegionName = (state: RootState, id: string) => {
  const region = getRegionById(state, id) || getRegionByPkgCode(state, id);
  return region ? region.name : '';
};

export const getPackages = (state: RootState) => state.filtersOptions.packages;
export const getPackageById = (state: RootState, id: string) => getPackages(state)[id];

export const getClassifications = (state: RootState) => state.filtersOptions.classifications;
export const getClassificationById = (state: RootState, id: string) => find(getClassifications(state), { id });

export const getClassificationByNodes = (state: RootState, id: string) =>
  find(getClassifications(state), (classification) => find(classification.nodes, { id }));
export const getClassificationNodesByName = (state: RootState, id: string) =>
  find(getClassificationByNodes(state, id).nodes, { id }).name;

export const getPorts = (state: RootState, id: string) => find(getRegions(state), (rgn) => find(rgn.ports, { id }));
export const getPortsByName = (state: RootState, id: string) => find(getPorts(state, id).ports, { id }).name;

export const getCabinTypeName = (state: RootState, code: string) =>
  find(getFiltersOptions(state).cabinTypes, { code })?.name;

/**
 * Get day numbers list from durations list
 * Example: getDurationDays([1-4, 5, 6-8, 9-12, 13]) ==> [1, 2, 3, 5, 7];
 * @param durations
 * @returns {*}
 */
export const getDurationDays = (durations: TFiltersDuration[]) =>
  durations.reduce<number[]>((acc, duration) => {
    for (let i = duration.min; i < (duration.max || duration.min) + 1; i += 1) {
      acc.push(i);
    }
    return acc;
  }, []);

export const selectDefaultMaxDate = createSelector(
  [
    getFiltersOptions,
    selectLookup,
    selectMnvvVoyage,
    (_, searchParams: URLSearchParams) => ({
      searchParams,
    }),
  ],
  (filtersOptions, lookup, mnvvVoyage, { searchParams }) => {
    const { isMNVV } = getMnvvReservation(searchParams);
    const mnvvMaxDate = calculateMaxDate(mnvvVoyage?.bookedDate);
    const currentDate = currentNYTime(lookup.serverISOtime);
    const maxDate = filtersOptions.maxDate ? parse(filtersOptions.maxDate) : currentDate;

    return isMNVV ? mnvvMaxDate : format(maxDate);
  },
);

export const selectDefaultMinDate = createSelector(getFiltersOptions, selectLookup, (filtersOptions, lookup) => {
  const currentDate = currentNYTime(lookup.serverISOtime);
  return filtersOptions.minDate || format(currentDate);
});
