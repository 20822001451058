import orderBy from 'lodash/orderBy';

import { getIsTaxesIncluded } from '@/helpers/pricing/lib/tax';
import { FiltersPriceType } from '@/infra/types/common/filters';

export const getAmountPerItemNew = (
  price,
  { cabins = 1, duration, priceType = FiltersPriceType.perSailor, sailors },
  key = 'amount',
) => {
  switch (priceType) {
    case FiltersPriceType.perCabin:
      return getAmountTaxAware(price, key) / cabins;
    case FiltersPriceType.perSailor:
      return getAmountTaxAware(price, key) / sailors;
    case FiltersPriceType.sailorPerNight:
      return getAmountPerVoyage(price, duration, { sailors }, key);
    default:
      return getAmountTaxAware(price, key) / sailors;
  }
};

export const getAmountTaxAware = (price = {}, key = 'amount') => {
  const { taxAmount } = price;
  const isTaxesIncluded = getIsTaxesIncluded();
  const result = parseFloat(price[key]);
  if (isTaxesIncluded || typeof taxAmount !== 'number') {
    return result;
  }

  return result - parseFloat(taxAmount);
};

export const getAmountPerVoyage = (price, duration, { sailors }, key = 'amount') =>
  getAmountTaxAware(price, key) / sailors / duration;

export const getAmountPerVoyageFlat = (price, duration, { sailors }) => price / sailors / duration;

export type TPriceRangeData = { maxPrice?: number; minPrice?: number };

export const getSailingPriceRangeForSailorPerNight = (sailings, { sailors }): TPriceRangeData => {
  const toBeAppliedPriceRange: TPriceRangeData = {};
  const allStartingPricesOrderedByAmount = orderBy(
    sailings.map((sailing) => sailing.sailingList).flat(),
    (s) => getAmountPerVoyage(s.startingPrice, s.duration, { sailors }),
    'asc',
  );
  if (allStartingPricesOrderedByAmount.length === 0) {
    return toBeAppliedPriceRange;
  }
  const { [0]: minPriceSailing, [allStartingPricesOrderedByAmount.length - 1]: maxPriceSailing } =
    allStartingPricesOrderedByAmount;
  toBeAppliedPriceRange.minPrice = Math.floor(
    getAmountPerVoyage(minPriceSailing.startingPrice, minPriceSailing.duration, { sailors }),
  );
  toBeAppliedPriceRange.maxPrice = Math.ceil(
    getAmountPerVoyage(maxPriceSailing.startingPrice, maxPriceSailing.duration, { sailors }),
  );
  return toBeAppliedPriceRange;
};

export const getAmountPerItem = (
  price,
  { cabins = 1, priceType = FiltersPriceType.perSailor, sailors },
  key = 'amount',
) => {
  if (priceType === FiltersPriceType.perCabin || priceType === FiltersPriceType.sailorPerNight) {
    return getAmountTaxAware(price, key) / cabins;
  }
  if (priceType === FiltersPriceType.perSailor) {
    return getAmountTaxAware(price, key) / sailors;
  }
  return getAmountTaxAware(price, key) / sailors;
};

export const getSailingsPriceRange = (sailings, { cabins, priceType, sailors }): TPriceRangeData => {
  const toBeAppliedPriceRange: TPriceRangeData = {};
  const allStartingPricesOrderedByAmount = orderBy(
    sailings.map((sailing) => sailing.sailingList.map((sailingList) => sailingList.startingPrice)).flat(),
    (s) => getAmountPerItem(s, { cabins, priceType, sailors }),
    'asc',
  );

  if (!allStartingPricesOrderedByAmount.length) {
    return toBeAppliedPriceRange;
  }
  const { [0]: minPrice, [allStartingPricesOrderedByAmount.length - 1]: maxPrice } = allStartingPricesOrderedByAmount;

  try {
    toBeAppliedPriceRange.minPrice = Math.floor(getAmountPerItem(minPrice, { cabins, priceType, sailors }));
    toBeAppliedPriceRange.maxPrice = Math.ceil(getAmountPerItem(maxPrice, { cabins, priceType, sailors }));
  } catch (e) {
    // console.log('error while cal min and max price', e);
  }
  return toBeAppliedPriceRange;
};
